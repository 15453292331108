<template>
    <div class="page-container">
        <div class="page-title">添加店铺成员一起经营自提点</div>
        <div class="search-container">
            <input v-model="keyword" class="search-input" placeholder="请输入您要添加的员工的手机号码"></input>
            <div class="search-button" @click="searchUser">
                搜索会员
            </div>
        </div>
        <div class="warning-container">
            <div class="warning-title">岗位说明:</div>
            <div class="warning-list">1.店员可操作查看功能-查看订单信息、核销商品</div>
            <div class="warning-list">2.店员暂不可查看操作功能-账户资产(佣金、提现等)</div>
            <div class="warning-list">3.店员可查看不可操作功能-自提点图片和店名</div>
        </div>
    </div>
</template>

<script>
  export default {
    name: 'add-worker',
    data () {
      return {
        keyword: ''
      }
    },
    mounted () {
      document.title = '添加自提点员工'
    },
    methods: {
      searchUser () {
        let params = {
          keyword: this.keyword
        }
        let query = encodeURIComponent(JSON.stringify(params))
        this.$platform.wxsdk.wxRoute({
          type: 'navigateTo',
          url: '/web/selfExtract/worker/search-user?query=' + query,
        })
      }
    }
  }
</script>

<style scoped lang="scss">
    .page-container {
        width: 100vw;
        height: 100vh;
        background: #fff;
        display: flex;
        flex-direction: column;

        .page-title {
            width: 100%;
            display: flex;
            font-size: 16px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #666666;
            align-items: center;
            justify-content: center;
            padding: 65px 0;
        }

        .search-container {
            display: flex;
            flex-direction: column;
            padding: 0 50px;

            .search-input {
                background: #F5F5F5;
                -webkit-border-radius: 23px;
                -moz-border-radius: 23px;
                border-radius: 23px;
                height: 45px;
                border: none;
                outline: none;
                font-size: 12px;
                text-align: center;
            }

            .search-button {
                height: 45px;
                background: linear-gradient(139deg, #FAC484 0%, #F46A17 100%);
                box-shadow: 0px 1px 6px 0px rgba(244, 106, 23, 0.55);
                border-radius: 23px;
                display: flex;
                align-items: center;
                justify-content: center;
                color: #fff;
                font-size: 16px;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 500;
                margin-top: 25px;
            }
        }

        .warning-container {
            width: 100%;
            margin-top: 60px;

            .warning-title {
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 14px;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 500;
                color: #979797;
                margin-bottom: 20px;
            }

            .warning-list {
                padding: 0 50px;
                font-size: 11px;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 500;
                color: #BFBFBF;
                margin-bottom: 10px;
            }
        }
    }
</style>